<template>
    <ul
        v-if="data.length"
        class="list-badges"
    >
        <li
            v-for="(badge, index) in data"
            :key="`item-badge-${index}`"
            :style="badge.style"
            :class="{ 'list-badges-item-discount': badge.type === 'discount' }"
            class="list-badges-item"
        >
            {{ badge.text }}
        </li>
    </ul>
</template>

<script>
export default {
    name: 'BaseListBadges',

    props: {
        data: {
            required: true,
            validator: (value) => ['undefined', 'boolean'].includes(typeof value) || Array.isArray(value),
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./../scss/05-components/list-badges";
</style>
