var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.length
    ? _c(
        "ul",
        { staticClass: "list-badges" },
        _vm._l(_vm.data, function (badge, index) {
          return _c(
            "li",
            {
              key: `item-badge-${index}`,
              staticClass: "list-badges-item",
              class: { "list-badges-item-discount": badge.type === "discount" },
              style: badge.style,
            },
            [_vm._v("\n        " + _vm._s(badge.text) + "\n    ")]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }